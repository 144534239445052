import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Тенісні корти Cleverus Land
			</title>
			<meta name={"description"} content={"вдосконаліть свою гру з допомогою Cleverus Land"} />
			<meta property={"og:title"} content={"Тенісні корти Cleverus Land"} />
			<meta property={"og:description"} content={"вдосконаліть свою гру з допомогою Cleverus Land"} />
			<meta property={"og:image"} content={"https://cleverusland.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cleverusland.com/img/935231-446.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cleverusland.com/img/935231-446.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cleverusland.com/img/935231-446.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cleverusland.com/img/935231-446.png"} />
		</Helmet>
		<Components.Header />
		<Components.Contacts />
		<Components.FooterMenu />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});